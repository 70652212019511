<style scoped>
.c-text-mist {
	filter: blur(0.25em);
	cursor: pointer;
}
.c-banlist-header {
	cursor: pointer;
	user-select: none;
}
</style>
<script>

import {mapGetters} from "vuex";
import Multiselect from "vue-multiselect";
import TimeAgo from 'vue2-timeago';
import {HalfCircleSpinner} from "epic-spinners";
import { VclFacebook, VclCode, VclList } from 'vue-content-loading';

import {get_acsrf_token, startsWith} from "@/methods";

export default {
	props: ['banlist_id', 'preview'],
	components: {
		VclFacebook,
		VclCode,
		VclList,
		// eslint-disable-next-line vue/no-unused-components
		HalfCircleSpinner,
		// eslint-disable-next-line vue/no-unused-components
		TimeAgo,
		Multiselect
	},
	methods: {
		handleError: function(error) {
			console.log(`[ERROR] ${error}`);
			this.error = true;
		},
		async getData() {
			try {
				let url = new URL(process.env.VUE_APP_ROOT_API + `v1/banmanager/${this.banlist_id}/info`);

				let response = await fetch(url, {
					method: 'GET',
					credentials: 'include'
				});

				if(response.ok) {
					let data = await response.json();
					this.banlist = data.banlist;
					this.ready = true;
				} else {
					if (response.status === 429) this.$toast.warning(this.$t('error.server.ratelimit.title'));
					throw new Error(`(${this.$vnode.componentOptions.tag}) Failed with API error ${response.status}=${response.statusText} (${response.url})`);
				}

			} catch (error) {
				this.error = true;
				console.log(`[ERROR] ${error}`);
			}
		},
		async retrieveAvailableServers() {
			try {
				let url = new URL(process.env.VUE_APP_ROOT_API + `v1/banmanager/${this.banlist_id}/applicable-servers`);

				let response = await fetch(url, {
					method: 'GET',
					credentials: 'include'
				});

				if(response.ok) {
					let data = await response.json();
					this.availableServers = data.servers;
				} else {
					if (response.status === 429) this.$toast.warning(this.$t('error.server.ratelimit.title'));
					throw new Error(`(${this.$vnode.componentOptions.tag}) Failed with API error ${response.status}=${response.statusText} (${response.url})`);
				}

			} catch (error) {
				this.error = true;
				console.log(`[ERROR] ${error}`);
			}
		},
		async applyBanlistToServers() {
			this.applyingBanlist = true;
			try {
				let acsrf_token = await get_acsrf_token();
				let payload = {
					acsrf_token: acsrf_token,
					servers: this.selectedServer
				};
				let response = await fetch(process.env.VUE_APP_ROOT_API + `v1/banmanager/${this.banlist_id}/multi-apply`, {
					method: 'POST',
					body: JSON.stringify(payload),
					credentials: 'include'
				});
				if(response.ok) {
					this.$refs.banlistAddModal.hide();
					this.$swal({
						icon: 'success',
						title: this.$t('banmanager.view.profile.add.success')
					});
				} else {
					if(response.status === 403) {
						this.$swal({
							icon: 'error',
							text: this.$t('error.permissions.message'),
							title: this.$t('error.permissions.title')
						});
					} else if(response.status === 429) {
						this.$swal({
							icon: 'warning',
							text: this.$t('error.server.ratelimit.action'),
							title: this.$t('error.server.ratelimit.title')
						});
					} else
						throw new Error(`(${this.$vnode.componentOptions.tag}) Failed with API error ${response.status}=${response.statusText} (${response.url})`);
				}
			} catch (error) {
				console.log(`[ERROR] ${error}`);
				this.$swal({
					icon: 'error',
					text: this.$t('error.server.generic.message')
				});
			}
			this.applyingBanlist = false;
		},
		async openAddToServerPopUp() {
			this.selectedServer = null;
			this.availableServers = null;

			this.$nextTick(() => {
				this.$refs.banlistAddModal.show();
				this.retrieveAvailableServers();
			});
		},
		currentDateTime(date) {
			if(!date || date === null) date = new Date();
			return `${date.getFullYear()}-${(date.getMonth()+1).pad()}-${date.getDate().pad()}T${date.getHours().pad()}:${date.getMinutes().pad()}`;
		},
		bannerUpdate(banner_url) {
			this.banlist.profile.banner_url = banner_url;
		},
		fixLink(url) {
			if(!url.startsWith('http') && !url.startsWith('ts3')) return `https://${url}`;
			return url;
		}
	},
	computed: {
		...mapGetters([
			'getUILanguage',
			'getDTLocale'
		])
	},
	async mounted() {
		await this.getData();
	},
	data() {
		return {
			ready: false,
			error: false,
			banlist: null,
			selectedServer: null,
			applyingBanlist: false,
			availableServers: null
		}
	}
};
</script>

<template>
	<!-- Error display -->
	<div v-if="ready && error">
		Error
	</div>
	<!-- Content -->
	<div v-else-if="ready && banlist">
		<div style="position: absolute !important;">
			<img :src="banlist.profile.banner_url" style="max-width: 316px; max-height: 12rem;" v-if="preview"/>
			<img :src="banlist.profile.banner_url" style="max-width: 100%; max-height: 12rem;" v-else/>
		</div>
		<div class="p-3" style="top: 0 !important; position: relative !important; height: 11rem; padding-bottom: 0 !important; margin-bottom: 0 !important;">
			<div class="row align-items-center" v-if="banlist.verified">
				<div class="col">
					<h6 class="m-0">
            <span class="badge badge-success">
              <i class="fas fa-badge-check text-white align-middle align-self-center" />
              Verified
            </span>
					</h6>
				</div>
				<div class="col-auto">
					<!-- <a href="#!" class="small">View More</a> -->
				</div>
			</div>
			<div class="row mt-2">
				<div class="col">
					<h5 class="bg-light m-0" style="padding: 0.35rem; width: max-content; border-radius: 20px; font-size: 1.25em;">
						{{ banlist.identifier }}
						<span class="badge badge-secondary text-black">
							{{ $t(`banmanager.view.profile.category.options.${banlist.profile.category}`) }}
						</span>
					</h5>
				</div>
			</div>
			<div class="d-flex justify-content-center ml-n3" style="position: absolute; bottom: 0.25rem; width: 100%;">
				<div class="row m-0 p-0">
					<div class="col" v-if="banlist.profile.contact.discord">
						<a :href="fixLink(banlist.profile.contact.discord)" target="_blank">
							<i class="fab fa-discord" style="font-size: 1.5rem;"/>
						</a>
					</div>

					<div class="col" v-if="banlist.profile.contact.teamspeak">
						<a :href="fixLink(banlist.profile.contact.teamspeak)" target="_blank">
							<i class="fab fa-teamspeak" style="font-size: 1.5rem;"/>
						</a>
					</div>

					<div class="col" v-if="banlist.profile.contact.website">
						<a :href="fixLink(banlist.profile.contact.website)" target="_blank">
							<i class="fad fa-external-link-alt" style="font-size: 1.5rem;"/>
						</a>
					</div>
				</div>
			</div>
		</div>

		<div class="p-3">
			<div class="row mt-2">
				<div class="col">
          <span class="text">
            {{ banlist.profile.description }}
          </span>
				</div>
			</div>
			<template v-if="banlist.public">
				<hr>
				<div class="row">
					<div class="col">
						<button class="btn btn-primary btn-block" v-on:click="openAddToServerPopUp()">
							<h6 class="p-0 m-0 text-uppercase">
								{{ $t(`banmanager.view.profile.add.button`) }}
							</h6>
						</button>
					</div>
				</div>
			</template>
		</div>

		<b-modal
				ref="banlistAddModal"
				size="lg"
				:title="$t('banmanager.view.profile.add.button')"
				title-class="font-18"
				hide-footer
		>
			<template v-if="!availableServers">
				<VclList height="200px" :speed="1" class="mt-3" primary="#FFFFFF" secondary="#bdbdbd"></VclList>
			</template>
			<template v-else>
				<multiselect
						v-model="selectedServer"
						label="id"
						track-by="id"
						:options="availableServers"
						:show-labels="false"
						:allow-empty="false"
						:searchable="true"
						:multiple="true"
				>
					<template slot="noOptions">
						{{ $t(`banmanager.view.profile.add.none`) }}
					</template>
					<template slot="singleLabel" slot-scope="props">
						<div>
                <span class="option__title align-middle h4">
                  {{ props.option.identifier }}
                </span>
							<div class="option__desc d-inline align-middle">
								<span>
									Cloud Id {{ props.option.id }}
								</span>
							</div>
						</div>
					</template>
					<template slot="option" slot-scope="props">
						<div>
							<div class="option__desc d-inline ml-2">
                  <span class="option__title align-middle h4">
                    {{ props.option.identifier }}
                  </span>
								<span class="option__small align-middle ml-2 h6">
                  <span>
                    Cloud Id {{ props.option.id }}
                  </span>
								</span>
							</div>
						</div>
					</template>
				</multiselect>
				<hr/>
				<div class="row">
					<div class="col">
						<button
								class="btn btn-primary btn-block"
								:class="{
									'disabled': !selectedServer || applyingBanlist
								}"
								:disabled="!selectedServer || applyingBanlist"
								v-on:click="applyBanlistToServers()"
						>
							<half-circle-spinner
									v-if="applyingBanlist"
									:animation-duration="1200"
									:size="16"
									class="align-middle d-inline-block"
							/>
							<h6 class="p-0 m-0 text-uppercase" v-if="!applyingBanlist">
								{{ $t(`banmanager.view.profile.add.confirm`) }}
							</h6>
						</button>
					</div>
				</div>
			</template>
		</b-modal>
	</div>
	<!-- Loading animation -->
	<div v-else>
		<div class="p-4">
			<VclFacebook :speed="1" primary="#FFFFFF" secondary="#bdbdbd"></VclFacebook>
			<VclCode :speed="1" class="mt-3" primary="#FFFFFF" secondary="#bdbdbd"></VclCode>
		</div>
	</div>
</template>
